// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grTKS5:nth-of-type(2){margin-top:100vh}.grTKS5::before{transition:background-color .2;background-color:var(--panel-bg-color, rgba(0, 0, 0, 0.6))}.grTKS5>:not(:last-child){margin-bottom:1.5rem}.grTKS5>h2,.grTKS5>h3{margin-left:auto !important;margin-right:auto !important;width:24rem;max-width:90%;color:#fefefe;line-height:1.25;text-align:center;text-transform:none}.grTKS5>h2{font-size:2.25rem}.grTKS5>h3{font-size:1.75rem}.grTKS5 a:link,.grTKS5 a:hover,.grTKS5 a:active,.grTKS5 a:visited{color:inherit !important;text-decoration:underline !important;text-decoration-color:rgba(255,255,255,.5) !important}.grTKS5 a:hover,.grTKS5 a:active{text-decoration-color:#fff !important}@media only screen and (min-width: 668px)and (max-width: 1022px){.grTKS5{width:42rem}}@media only screen and (min-width: 1023px){.grTKS5:nth-of-type(2)[data-align=left],.grTKS5:nth-of-type(2)[data-align=right]{margin-top:40vh}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "grTKS5"
};
export default ___CSS_LOADER_EXPORT___;
