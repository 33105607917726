// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3rk6F1{position:relative;z-index:1;background-color:#3c6998;transition:background .2s}._3rk6F1 svg{background:none;width:100vw;height:100vh}._3rk6F1 .fEMHO9{fill:rgba(255,255,255,.8);fill:var(--group-color, rgba(255, 255, 255, 0.8));stroke-width:0;stroke:#000}._3rk6F1 .IXzG1x text{font-size:15px;fill:#fff;text-anchor:middle}._3rk6F1 .IXzG1x path{fill:none;stroke:rgba(255,255,255,.6);stroke-width:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dots": "_3rk6F1",
	"population": "fEMHO9",
	"group-label": "IXzG1x",
	"groupLabel": "IXzG1x"
};
export default ___CSS_LOADER_EXPORT___;
